/* Base Tailwind Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
@layer base {
  body {
    @apply font-sans text-prompt-white bg-prompt-bg;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-heading font-semibold;
  }

  p {
    @apply font-sans;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}








/* Typography & Text Styles */
.home-title {
  @apply font-heading;
  font-weight: 700;
}

.section-title {
  @apply font-heading font-bold text-xl text-prompt-white;
  font-size: 1.7rem; 
  letter-spacing: 0.025em;
  margin-bottom: 0.25rem;
}










/* Layout & Structure */
.content-wrapper {
  position: relative;
  z-index: 0;
}













/* Header Styles */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  z-index: 1000;
}

.home-page header {
  position: sticky;
  top: 0;
}













/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 4rem; 
  left: 0;
  bottom: 0;
  width: 18rem;
  z-index: 900;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex; 
  flex-direction: column; 
}

.sidebar-scroll {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar.open {
  transform: translateX(0);
}













/* Main Content Styles */
.main-content {
  position: relative;
  flex-grow: 1;
  height: calc(100vh - 4rem); 
  overflow: hidden; 
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 100%;
}

.main-content-scroll {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-content.sidebar-open {
  margin-left: 18rem;
  width: calc(100% - 18rem);
}













/* Responsive Layouts */
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 4rem;
    bottom: 0;
    width: 18rem;
  }
  
  .main-content {
    height: calc(100vh - 4rem);
  }
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    z-index: 999;
  }

  .main-content,
  .main-content.sidebar-open {
    margin-left: 0;
    width: 100%;
  }
}

/* UI Component Styles */
.toggle-switch-container {
  margin-top: -12px;
}

.header-dropdown {
  z-index: 1100;
}

.dropdown-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1090;
}









/* Home Page Specific Styles */
.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-content {
  flex: 1;
  overflow-y: auto;
}

.home-page > footer {
  flex-shrink: 0;
}









/* Scrollbar Styles */
@layer utilities {
  .scrollbar-custom {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.prompt-medium-gray') theme('colors.prompt-deep-gray');
  }

  .scrollbar-custom::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background: theme('colors.prompt-dark-gray');
    border-radius: 4px;
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: theme('colors.prompt-light-gray');
    border-radius: 4px;
    border: 2px solid theme('colors.prompt-dark-gray');
  }

  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: theme('colors.prompt-light-gray');
  }
}










/* Markdown Styles */
.tutorial-markdown {
  line-height: 1.6;
}

.tutorial-markdown h1 {
  font-weight: bold;
  color: theme('colors.prompt-white');
  font-size: 1.75rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.tutorial-markdown h1 + p,
.tutorial-markdown h1 + ol,
.tutorial-markdown h1 + ul {
  margin-top: 1.5rem;
}

.tutorial-markdown h2 {
  font-weight: bold;
  color: theme('colors.prompt-white');
  font-size: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 2.5rem; 
}

.tutorial-markdown h2 + p,
.tutorial-markdown h2 + ol,
.tutorial-markdown h2 + ul {
  margin-top: 2rem; 
}

.tutorial-markdown h3 {
  font-weight: bold;
  color: theme('colors.prompt-white');
  font-size: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 0.15rem;
}

.tutorial-markdown p {
  color: theme('colors.prompt-light-gray');
  margin-bottom: 1.5rem;
}

.tutorial-markdown ul, .tutorial-markdown ol {
  color: theme('colors.prompt-light-gray');
  margin-bottom: 1.5rem;
  padding-left: 2em;
  list-style-position: outside;
}

.tutorial-markdown ol {
  list-style-type: decimal;
}

.tutorial-markdown li {
  margin-bottom: 0.25rem;
}











/* Tooltip Styles */
.tooltip-markdown {
  line-height: 1.4;
}

.tooltip-markdown p {
  margin-bottom: 0.75rem;
}

.tooltip-markdown ul, .tooltip-markdown ol {
  margin-bottom: 0.75rem;
  padding-left: 1.5em;
}

.tooltip-markdown li {
  margin-bottom: 0.25rem;
}

.tooltip-content {
  max-height: 400px; 
  overflow-y: auto;
  padding-right: 10px;
}

.tooltip-content::-webkit-scrollbar {
  width: 4px;
}

.tooltip-content::-webkit-scrollbar-track {
  background: theme('colors.prompt-deep-gray');
  border-radius: 10px;
}

.tooltip-content::-webkit-scrollbar-thumb {
  background-color: theme('colors.prompt-light-gray');
  border-radius: 10px;
  border: 2px solid theme('colors.prompt-dark-gray');
}

.tooltip-content::-webkit-scrollbar-thumb:hover {
  background-color: theme('colors.prompt-light-gray');
}













/* Markdown Content Styles */
.markdown-content strong {
  font-weight: bold;
}

.markdown-content em {
  font-style: italic;
}

.markdown-content code {
  background-color: theme('colors.prompt-light-gray');
  color: theme('colors.prompt-white');
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.markdown-content pre {
  background-color: theme('colors.prompt-light-gray');
  color: theme('colors.prompt-white');
  padding: 1em;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}






/* Utility Classes */
.grecaptcha-badge {
  visibility: hidden !important;
}